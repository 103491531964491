import queryString from 'query-string';
import { QR_ORDERING_PLAN } from '../component/OrderStatus/orderEnums';
import { removeLocalItem, setLocalItem } from '../webStorage/localStorage';
import { getSessionItem, setSessionItem } from '../webStorage/sessionStorage';
import { DINEIN_TYPE } from '../webStorage/keys/sessionKeys';
import { removeOrderLoader } from '../redux/createOrder/createOrderActions';
import { dynamicQrTrack } from '../analytics/tracker/events/qrEvents';

export function extractEpochFromValidity(validity) {
  // Check if validity is null, empty, or not long enough to contain an epoch
  if (!validity || validity.length <= 8) {
    console.warn('Invalid validity string.');
    return null; // Return null or handle the error gracefully
  }

  const epochString = validity.slice(4, -4); // Extract epoch part
  const epochValue = parseInt(epochString, 10); // Convert to integer

  // Check if the extracted part is a valid number
  if (isNaN(epochValue)) {
    console.warn('Invalid epoch value extracted.');
    return null;
  }

  return epochValue;
}

export function isEpochExpired(epochTime) {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  return epochTime < currentTime;
}

export const isDynamicPlan = (plan) =>
  [QR_ORDERING_PLAN.DYNAMIC, QR_ORDERING_PLAN.BOTH].includes(plan);

export const isStaticPlan = (plan) =>
  [QR_ORDERING_PLAN.STATIC, QR_ORDERING_PLAN.BOTH].includes(plan);

const handlePlanMismatch = (type, showQrExpiry) => {
  showQrExpiry(type === 'dynamic' ? 1 : 2);
  return 'early_return';
};

export const processQRCode = (data, plan, showQrExpiry) => {
  const parsedData = queryString.parse(data.replace(/"/g, ''));
  const validity = parsedData?.validity;
  const epoch = extractEpochFromValidity(validity);

  dynamicQrTrack({
    flow: 'processQRCode',
    plan,
    parsedData,
    validity,
    epoch,
  });

  if (epoch && data.includes('dynamic')) {
    if (!isDynamicPlan(plan)) {
      handlePlanMismatch('dynamic', showQrExpiry);
      return 'early_return';
    } else if (isEpochExpired(epoch)) {
      showQrExpiry(1);
      return 'early_return';
    } else {
      setLocalItem('dynamic_epoch', epoch);
    }
  } else if (data.includes('backend') && !isStaticPlan(plan)) {
    handlePlanMismatch('static', showQrExpiry);
    return 'early_return';
  } else {
    removeLocalItem('dynamic_epoch');
  }

  return 'success';
};

export const executeFcQrCode = (plan, query, showQrExpiry) => {
  const isExecuted = JSON.parse(getSessionItem('is_fc_qr_code_executed') || false);
  if (isExecuted && !query?.validity) return;

  const validity = query?.validity;
  const epoch = extractEpochFromValidity(validity);

  dynamicQrTrack({
    flow: 'executeFcQrCode',
    plan,
    isExecuted,
    validity,
    epoch,
  });

  if (epoch && isDynamicPlan(plan)) {
    if (isEpochExpired(epoch)) {
      return showQrExpiry(1);
    } else {
      setLocalItem('dynamic_epoch_fc', epoch);
    }
  } else if (epoch && !isDynamicPlan(plan)) {
    return showQrExpiry(1);
  } else if (!epoch && !isStaticPlan(plan)) {
    return showQrExpiry(2);
  } else {
    removeLocalItem('dynamic_epoch_fc');
  }

  setSessionItem('is_fc_qr_code_executed', true);
};

export const handleQrExpiryFlow = (orderType, plan, epoch, showQrExpiry, dispatch = null) => {
  if (orderType !== DINEIN_TYPE) return 'success';

  dynamicQrTrack({
    flow: 'handleQrExpiryFlow',
    orderType,
    plan,
    epoch,
  });

  if (epoch && isDynamicPlan(plan) && isEpochExpired(epoch)) {
    dispatch?.(removeOrderLoader(''));
    showQrExpiry(1);
    return 'early_return';
  } else if (epoch && !isDynamicPlan(plan)) {
    dispatch?.(removeOrderLoader(''));
    showQrExpiry(1);
    return 'early_return';
  } else if (!epoch && !isStaticPlan(plan)) {
    dispatch?.(removeOrderLoader(''));
    showQrExpiry(2);
    return 'early_return';
  }

  return 'success';
};
