export const qrTrack = (data) => {
  try {
    window.__bgtracker.track('QR SCAN', { hitName: 'QR SCAN', ...data });
  } catch (error) {
    console.log(error);
  }
};

export const dynamicQrTrack = (data) => {
  try {
    window.__bgtracker.track('DYNAMIC_QR', { hitName: 'DYNAMIC_QR', ...data });
  } catch (error) {
    console.log(error);
  }
};
